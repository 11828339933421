<template>
  <v-menu
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
    bottom
    origin="center center"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <a
        class="filtro"
        color="indigo"
        dark
        v-on="on"
      >
        <v-icon
          left
          color="#F7A400"
        >mdi-filter-variant</v-icon> <span style="color:#000">{{$t('filter')}}</span>
      </a>
    </template>

    <v-card class="px-3 py-3">
      <div class="title pl-3">{{$t('send_test_filter')}}</div>
      <v-container fluid>
        <v-card flat max-width="300">
          <v-row>
            <v-col class="12" md="12">
              <v-autocomplete
                v-model="filter.category"
                :items="categories"
                item-text="text"
                item-value="key"
                outlined
                :label="$t('category')"
                dense
                small-chips
                hide-details
                @change="changedFieldSingle('category')"
              ></v-autocomplete>
            </v-col>
            <v-col class="12" md="12">
              <v-autocomplete
                v-model="filter.areas"
                :items="getTrainingAreas($i18n.locale)"
                item-text="text"
                item-value="key"
                outlined
                :label="$t('academicEduarea')"
                dense
                multiple
                small-chips
                hide-details
                @change="changedFieldSingle('areas')"
              ></v-autocomplete>
            </v-col>
            <v-col class="12" md="12">
              <v-autocomplete
                v-model="filter.positions"
                :items="getPositions($i18n.locale)"
                item-text="text"
                item-value="key"
                outlined
                :label="$t('form_user_Office')"
                dense
                multiple
                small-chips
                hide-details
                @change="changedFieldSingle('positions')"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
            <v-btn
              text
              class="mx-2"
              @click="clean()"
              color="primary"
            >{{$t('clean')}}</v-btn>
            <v-btn
              color="primary"
              @click="$emit('apply', filter)"
            >{{$t('search')}}</v-btn>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TestFilter',
  data: () => ({
    filter: {
      areas: undefined,
      category: undefined,
      positions: undefined
    }
  }),
  created() {
    // eslint-disable-next-line no-undef
    Fire.$on('clearTestFilter', () => {
      // this.filter = {
      //   areas: undefined,
      //   category: undefined,
      //   positions: undefined
      // }
      this.clean()
    })
  },
  computed: {
    ...mapGetters({
      getTrainingAreas: 'library/trainingAreas',
      getPositions: 'library/positions'
    }),
    categories () {
      return [
        { text: this.$t('test_form_cat_1'), key: 'verbalUnderstanding' },
        { text: this.$t('test_form_cat_2'), key: 'skills' },
        { text: this.$t('test_form_cat_3'), key: 'games' },
        { text: this.$t('test_form_cat_4'), key: 'situationalJudgment' },
        { text: this.$t('test_form_cat_5'), key: 'abstractThinking' },
        { text: this.$t('test_form_cat_6'), key: 'personality' },
        { text: this.$t('test_form_cat_7'), key: 'logicalReasoning' },
        { text: this.$t('test_form_cat_8'), key: 'simulation' },
      ]
    },
  },
  methods: {
    changedFieldSingle(field) {
      if (!this.filter[field] || this.filter[field].length == 0) {
        this.filter[field] = undefined;
        this.$emit('apply', this.filter)
        
      }
    },
    clean () {
      this.filter = {
        areas: undefined,
        category: undefined,
        positions: undefined
      }
      this.$emit('clean', this.filter)
    }
  }
}
</script>